.services-page{
    background-color: #03030A;
}

/* hero section */

.services-hero{
    height: 100vh;
    min-height: 800px;
    background-image: url('../../assets/homepage-background.png');
    background-size: cover;
    background-repeat: no-repeat;
    /* background-position-y: -150px;
    background-position-x: -80px; */
    padding-bottom: 15vh;
    position: relative;
    display: flex;
    align-items: flex-end;
}


.services-hero .inner-container .left .bar {
    height: 100%;
    width: 3px;
    background: #815E3F;
    position: relative;
}
.services-hero .inner-container .left .bar::before {
    content: '';
    position: absolute;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: #A88E77;
    border: 3px solid #3B3A48;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 0;
    z-index: 99;
}

.services-hero::after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30vh;
    background: linear-gradient(0deg, #03030A,#03030a00);
    box-sizing: border-box;
    z-index: 0;
}

/* @media (max-height: 1200px){
    .services-hero{
        padding-left: 75px;
    }
} */

.services-hero .container{
    padding-left: 100px;
    position: relative;
    z-index: 2;
    /* padding-top: 50px; */
}

.services-hero .inner-container:first-child .content{
    padding-bottom: 25px;
}

@media (max-width: 1400px){
    .services-hero{
        padding-top: 15vh;
    }
}

@media (max-width: 780px){
    .services-hero{
        background-position-y: 0;
        background-position-x: 0;
    }
    .about-section .container,
    .services-hero .container{
        padding-left: 0;
        margin: unset;
    }
}


.services-hero .container .inner-container .left.extra{
    padding-left: 33px;
    display: unset;
}

.services-hero .inner-container .content h1{
    margin: 0;
    color: white;
    font-family: 'Libre Badoni';
    font-size: 52px;
    font-style: italic;
    margin-bottom: 15px;
    width: fit-content;
    padding-top: 70px;
}

.services-hero .inner-container .content p{
    max-width: 750px;
}

@media (max-width: 1430px) {
    .services-hero .inner-container .content h1{
        font-size: 50px;
    }
}

@media (max-width: 1200px){
    .services-hero .container{
        padding-left: 0;
    }
    .services-hero .inner-container .content h1{
        font-size: 45px;
    }
    .services-hero .inner-container .content p{
        max-width: 650px;
    }
}

@media (max-width: 700px){
    .services-hero .inner-container .content h1{
        max-width: 400px;
        font-size: 36px;
    }
}

@media (max-width: 450px){
    .services-hero .inner-container .content h1{
        font-size: 32px;
    }
}

/* hero section end */


/* About Section */

.e-commerce{
    margin-top: -15vh;
    position: relative;
    z-index: 2;
}

.e-commerce .container{
    padding-left: 100px;
}

.e-commerce .inner-container .left.icon .i{
    color: #815E3F;
}

.e-commerce .inner-container .left.content-bar{
    height: 270px;
}

.e-commerce .inner-container .left.icon::before{
    background-image: radial-gradient( #815E3F, #412f2000 70%);
}

.e-commerce .inner-container .left .bar {
    height: 100%;
    width: 3px;
    background-image: linear-gradient( #815E3F 60%, #412f2000);

}

.e-commerce .inner-container .left .top-bar{
    background: linear-gradient( #412f2000, #815E3F);
}

.e-commerce .inner-container .content{
    height: 100%;
}

.e-commerce .inner-container .content p span{
    color: #815E3F;
}

@media (max-width: 1200px){
    .e-commerce .container{
        padding-left: 0;
    }   
    .e-commerce .inner-container .content.title h2{
        font-size: 35px;
    }
    .e-commerce .inner-container .content p{
        max-width: 750px;
    }

}

.e-commerce .container:nth-child(2){
    padding-left: 0;
}

@media (max-width: 1400px) {
    .e-commerce .container:nth-child(2){
        padding-left: 10px;
    }
}

.e-commerce .e-commerce-card{
    display: flex;
    border-radius: 16px;
    background-color: #0D1117;
    border: 2px solid #4e4e4e;
    position: relative;
    height: 490px;
    box-shadow: rgba(50, 50, 93, 0.15) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

/* e-commerce end */

/* cross platform */
.cross-platform{
    position: relative;
    z-index: 1;
}
.cross-platform::after{
    content: '';
    position: absolute;
    transform: translate(-50%, -50%);
    top: 60%;
    left: 50%;
    width: 100%;
    height: 130%;
    background-image: linear-gradient(#412f2000, #0B0416 25% 75%, #412f2000);
    z-index: -1;
}
.cross-platform .container:first-child{
    padding-left: 100px;
}
.cross-platform .inner-container .left .top-bar{
    background: linear-gradient( #412f2000,#F35D9E);
}

@media (max-width: 1200px) {
    .cross-platform .container:first-child{
        padding-left: unset;
    }
}


@media (max-width: 1400px) {
    .cross-platform .container:nth-child(2){
        padding-left: 10px;
    }
}

.cross-platform .inner-container .left.icon .i{
    color: #F35D9E;
}

.cross-platform .inner-container .left.content-bar{
    height: 270px;
}

.cross-platform .inner-container .left.icon::before{
    background-image: radial-gradient( #F35D9E, #412f2000 70%);
}

.cross-platform .inner-container .left .bar {
    height: 100%;
    width: 3px;
    background-image: linear-gradient( #F35D9E 75%, #fff);
}
.cross-platform .inner-container{
    position: relative;
}
.cross-platform .inner-container .content.title{
    z-index: 2;
}

.cross-platform .inner-container .content{
    height: 100%;
    display: flex;
    justify-content: space-between;
    
}

@media (max-width: 992px){
    .cross-platform .inner-container .content{
        position: relative;
        z-index: 1;
        overflow-x: hidden;
    }

    .cross-platform .inner-container .content img{
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        right: -10%;
        z-index: -1;
        opacity: .2 !important;
    }
}

.cross-platform .inner-container .content img{
    width: 450px;
    opacity: .9;
    margin-top: -150px;
}

@media (max-width: 1400px) {
    .cross-platform .inner-container .content img{
        width: 300px;
        height: 300px;
        margin-top: -80px;
    }

}

@media (max-width: 1200px) {
    .cross-platform .inner-container .content img{
        /* width: 250px;
        height: 250px; */
        /* margin-top: unset; */
    }

}

.cross-platform .inner-container .content p {
    max-width: 550px;
}

.cross-platform .inner-container .content p span{
    color: #F35D9E;
}

@media (max-width: 780px){
    .cross-platform .container{
        padding-left: 0;
    } 
}

.cross-platform .inner-container .content.extention{
    position: relative;
    margin-top: -23px;
}


/* cross platform end */

/* custom software */

.custom-software{
    position: relative;
    z-index: 1;
}
.custom-software::after{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: linear;
    z-index: -1;
}
.custom-software .container:first-child{
    padding-left: 100px;
    margin-bottom: 0;
}
.custom-software .inner-container .left .top-bar{
    background: linear-gradient( #412f2000,#F35D9E);
}

@media (max-width: 1200px) {
    .custom-software .container:first-child{
        padding-left: unset;
    }
}


@media (max-width: 1400px) {
    .custom-software .container:nth-child(2){
        padding-left: 10px;
    }
}

.custom-software .inner-container .left.icon .i{
    color: #fff;
}

.custom-software .inner-container .left.content-bar{
    height: 270px;
}


.custom-software .inner-container .left.content-bar{
    height: 270px;
}

.custom-software .inner-container .left.icon::before{
    background-image: radial-gradient( #fff, #412f2000 70%);
}

.custom-software .inner-container .left .bar {
    height: 100%;
    width: 3px;
    background-image: linear-gradient( #fff 75%, #B246C5);
}

.custom-software .inner-container .content p {
    max-width: 750px;
}

.custom-software .inner-container .content .mock-editor{
    /* height: 300px; */
    max-width: 700px;
    /* background-color: blue; */
    margin-bottom: 50px;
    font-size: 18px;
}

/* custom software end */


/* uiux design */

.uiux-design{
    position: relative;
    z-index: 1;
}
.uiux-design::after{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 110%;
    /* background: url('../../assets/jake-weirick-Q_RBVFFXR_g-unsplash.jpg'); */
    z-index: -1;
}
/* .uiux-design .container:first-child{
    padding-left: 100px;
    margin-bottom: 0;
} */

.uiux-design .container:nth-child(2){
    padding-left: 100px;
}

@media (max-width: 1200px) {
    .uiux-design .container:nth-child(2){
        padding-left: unset;
    }
}

.uiux-design .inner-container .left.icon .i{
    color: #B246C5;
}

.uiux-design .inner-container .left.content-bar{
    height: 270px;
}


.uiux-design .inner-container .left.content-bar{
    height: 270px;
}

.uiux-design .inner-container .left.icon::before{
    background-image: radial-gradient( #B246C5, #412f2000 70%);
}

.uiux-design .inner-container .left .bar {
    height: 100%;
    width: 3px;
    background-image: linear-gradient( #B246C5 75%, #412f2000);
}

.uiux-design .inner-container .content p {
    max-width: 750px;
}


.uiux-design .small-cards-container{
    margin-top: 0;
}

.uiux-design .small-cards-container .card{
    width: 667px;
    padding: 90px 45px;
    position: relative;
    background-color: #4f1e6428;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; */
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.uiux-design .small-cards-container .card .card-content{
    display: unset ;
}

.uiux-design .small-cards-container .card .card-content h2{
    font-size: 50px;
    text-transform: uppercase;
    font-family: helvetica;
    font-weight: 100;
}
.uiux-design .small-cards-container .card .card-content h2 span{
    color: #B246C5;
    letter-spacing: 25px;
    font-weight: 400;
}
.uiux-design .small-cards-container .card .card-content .card-presentation{
    position: absolute;
    top: 0;
    height: 720px;
    width: min-content;
    /* transform: translateX(0%); */
    right: 40px;
}

.uiux-design .small-cards-container .card .card-content .card-presentation img{
    height: 100%;
    width: auto;
}

.uiux-design .small-cards-container .card:last-child .card-content .card-presentation{
    width: 100%;
    height: fit-content;
    right: 0;
    bottom: 0;
    top: unset;
    display: unset;
}
.uiux-design .small-cards-container .card:last-child .card-content .card-presentation img{
    width: 100%;
    height: auto;
}

@media (max-width: 1400px){
    .uiux-design .small-cards-container .card{
        padding: 80px 45px;
        height: 650px;
    }
    .uiux-design .small-cards-container .card:last-child{
        padding: 40px 35px 80px;
    }
    .uiux-design .small-cards-container .card .card-content .card-disc{
        padding: 0;
    }
    .uiux-design .small-cards-container .card:first-child .card-content .card-presentation{
        height: 580px;
        right: 30px;
    }
    .uiux-design .small-cards-container .card:first-child .card-content h2 span{
        letter-spacing: 20.5px;
    }
    .uiux-design .small-cards-container .card:first-child .card-content h2{
        font-size: 42px;
    }
    .uiux-design .small-cards-container .card:last-child .card-content p{
        margin-right: unset;
    }
}

@media (max-width: 1200px){
    .uiux-design .small-cards-container .card{
        padding: 80px 35px;
        height: 550px;
    }
    .uiux-design .small-cards-container .card:last-child{
        padding: 40px 35px 80px;
        height: 550px;
    }
    .uiux-design .small-cards-container .card .card-content .card-disc{
        padding: 0;
    }
    .uiux-design .small-cards-container .card:first-child .card-content .card-presentation{
        height: 500px;
        right: 30px;
    }
    .uiux-design .small-cards-container .card:first-child .card-content h2 span{
        letter-spacing: 17px;
    }
    .uiux-design .small-cards-container .card:first-child .card-content h2{
        font-size: 35px;
    }
}

@media (max-width: 992px){
    .uiux-design .small-cards-container .card{
        padding: 80px 35px;
        max-width: 450px;
        height: 550px;
    }
}
@media (max-width: 550px){
    .uiux-design .small-cards-container .card{
        max-width: 100%;
    }
}
@media (max-width: 500px){
    .uiux-design .small-cards-container .card{
        max-width: 100%;
    }
    .uiux-design .small-cards-container .card:first-child .card-content h2 span{
        letter-spacing: 13.75px;
    }
    .uiux-design .small-cards-container .card:first-child .card-content h2{
        font-size: 28px;
    }
}

@media (max-width: 448px){
    .uiux-design .small-cards-container .card:first-child .card-content .card-presentation{
        height: 450px;
        right: 20px;
    }
}

@media (max-width: 421px){
    .uiux-design .small-cards-container .card:first-child .card-content .card-presentation{
        height: 400px;
        right: 20px;
    }
    .uiux-design .small-cards-container .card:first-child .card-content h2 span{
        letter-spacing: 11.3px;
    }
    .uiux-design .small-cards-container .card:first-child .card-content h2{
        font-size: 23px;
    }
    .uiux-design .small-cards-container .card{
        height: 450px !important;
    }
    .uiux-design .small-cards-container .card:last-child{
        padding: 30px 20px 80px;
    }
}
/* uiux design end */

/* tailored solutions */

.tailored-solutions .container:first-child{
    padding-left: 100px;
}

.tailored-solutions .inner-container .left .bar{
    position: relative;
}

.tailored-solutions .inner-container .left .bar::before {
    content: '';
    position: absolute;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: #A88E77;
    border: 3px solid #3B3A48;
    transform: translate(-50%, -50%);
    left: 50%;
    bottom: -20px;
    z-index: 99;
}

@media (max-width: 1200px) {
    .tailored-solutions .container:first-child{
        padding-left: unset;
    }
}

@media (max-width: 1400px) {
    .tailored-solutions .container:nth-child(2){
        padding-left: 10px;
    }
}

.tailored-solutions .inner-container .left.icon .i{
    color: #815E3F;
}

.tailored-solutions .inner-container .left.content-bar{
    height: 270px;
}

.tailored-solutions .inner-container .left.icon::before{
    background-image: radial-gradient( #815E3F, #412f2000 70%);
}

.tailored-solutions .inner-container .left .bar {
    height: 100%;
    width: 3px;
    background-color: #815E3F;
}

.tailored-solutions .inner-container .left .bar.end {
    height: 100%;
    width: 3px;
    height: 120px;
    background: linear-gradient(#815E3F, #412f2000);
}

.tailored-solutions .inner-container .left .top-bar{
    background: linear-gradient( #412f2000, #815E3F);
}

.tailored-solutions .inner-container .content p {
    max-width: 800px;
}

.tailored-solutions .inner-container .content ul {
    font-size: 22px;
    max-width: 800px;
    opacity: .95;
    position: relative;
    padding-left: 25px;
    margin-bottom: 60px;
}

.tailored-solutions .inner-container .content ul::before {
    content: '';
    position: absolute;
    height: 135%;
    width: 35px;
    transform: translateY(-50%);
    top: 52%;
    border: 3px solid #815E3F;
    border-radius: 10px;
    left: -35px;
}

.tailored-solutions .inner-container .content ul li{
    position: relative;
    margin-bottom: 15px;
}
.tailored-solutions .inner-container .content ul li::before{
    content: '';
    position: absolute;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: 3px solid #815E3F;
    left: -35px;
    top: 4px;
}
.tailored-solutions .inner-container .content ul li::after{
    content: '';
    position: absolute;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    background-image: radial-gradient( #815E3F, #412f2000 70%);

    left: -40px;
    top: -2px;
}

.tailored-solutions .inner-container .content ul li span{
    font-weight: bold;
    opacity: 1;
}

@media(max-width: 780px){
    .tailored-solutions .inner-container .content ul {
        font-size: 18px;
        padding-left: 36px;
    }
    .tailored-solutions .inner-container .content ul::before{
        width: 30px;
        left: -20px;
    }
}

@media(max-width: 560px){
    .tailored-solutions .inner-container .content ul::before{
        height: 120%;
    }
}

/* tailored solutions end */