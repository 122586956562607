/* Hero Section */

.homepage-hero{
    height: 100vh;
    min-height: 800px;
    background-image: url('../../assets/homepage-background.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: -150px;
    background-position-x: -80px;
    padding-top: 22vh;
    position: relative;
}

.homepage-hero::after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30vh;
    background: linear-gradient(0deg, #03030A,#03030a00);
    box-sizing: border-box;
}

/* @media (max-height: 1200px){
    .homepage-hero{
        padding-left: 75px;
    }
} */

.homepage-hero .container{
    padding-left: 100px;
    position: relative;
    z-index: 2;
}

.homepage-hero .inner-container:first-child{
    align-items: flex-end;
}
.homepage-hero .inner-container:first-child .content{
    padding-bottom: 25px;
}

@media (max-width: 1400px){
    .homepage-hero{
        padding-top: 15vh;
    }
}

@media (max-width: 1200px){
    .homepage-hero .container{
        padding-left: 0;
    }
}

@media (max-width: 780px){
    .homepage-hero{
        background-position-y: 0;
        background-position-x: 0;
    }
    .about-section .container,
    .homepage-hero .container{
        padding-left: 0;
    }
}


.homepage-hero .container .inner-container .left.extra{
    padding-left: 33px;
    display: unset;
}

.homepage-hero .inner-container .content h1{
    margin: 0;
    color: white;
    font-family: 'Libre Badoni';
    font-size: 58px;
    font-style: italic;
    margin-bottom: 15px;
    width: fit-content;
}

.homepage-hero .inner-container .content p{
    font-size: 27px;
    opacity: .75;
    max-width: 820px;
    margin-bottom: 25px;
}

@media (max-width: 1430px) {
    .homepage-hero .inner-container .content h1{
        font-size: 38px;
    }
    .homepage-hero .inner-container .content p{
        font-size: 16px;
        max-width: 550px;
    }
}

@media (max-width: 1200px) {
    .homepage-hero .inner-container .content h1{
        font-size: 38px;
        /* width: 400px; */
    }
    .homepage-hero .inner-container .content p{
        font-size: 18px;
        max-width: 500px;
    }
}

@media (max-width: 700px){
    .homepage-hero .inner-container .content h1{
        max-width: 400px;
        font-size: 36px;
    }
}

@media (max-width: 450px){
    .homepage-hero .inner-container .content h1{
        font-size: 32px;
    }
}

 .inner-container .left.icon .i{
    color: #A88E77;
}

 .inner-container .left.icon::before{
    color: #A88E77;
    background-image: radial-gradient( #A88E77, #412f2000 70%);
}

.homepage-hero .inner-container .content.call-to-action{
    display: flex;
    gap: 15px;
}

.homepage-hero .inner-container .content.call-to-action button{
    background-color: white;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 23px;
    text-transform: capitalize;
    width: 260px;
    height: 40px;
    color: #02010D;
}



.homepage-hero .inner-container .content.call-to-action button:last-child{
    background-color: transparent;
    color: white;
    border: 2px solid white;
}

@media (max-width: 1430px){
    .homepage-hero .inner-container .content.call-to-action button{
        font-size: 16px;
        height: 30px;
        width: 180px;
    }
}

@media (max-width: 900px){
    .homepage-hero .inner-container .content.call-to-action button{
        width: 150px;
    }
}

@media (max-width: 450px){
    .homepage-hero .inner-container .content.call-to-action button{
        width: unset;
        padding: 5px 15px;
    }
}

.homepage-hero .inner-container.sml{
    align-items: start;
}

.homepage-hero .inner-container .left.play{
    height: 160px;
    position: relative;
}

.homepage-hero .inner-container .left.play .play-button{
    position: absolute;
    bottom: 2px;
    left: 1px;
    height: 65px;
    width: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: .3s;
    border-radius: 50%;
    transform: translateX(-50%);
    left: 50%;
}

.homepage-hero .inner-container .left.play .play-button:hover{
    background-color: rgba(255, 255, 255, 0.15);
}

.homepage-hero .inner-container .left.play .play-button .i{
    font-size: 40px;
    padding-left: 2px;
}

.homepage-hero .inner-container .content.sml{
    margin-top: 37px;
}

.homepage-hero .inner-container .social-links{
    display: flex;
    gap: 15px;
}

.homepage-hero .inner-container .social-links .i{
    font-size: 38px;
    color: var(--bluish-white);
    transition: .3s;
    opacity: .8;
}

.homepage-hero .inner-container .social-links .i:hover{
    opacity: 1;
}

.homepage-hero .inner-container.sml .left.play{
    display: flex;
    justify-content: center;
}

@media (max-width: 1430px){
    .homepage-hero .inner-container .social-links .i{
        font-size: 30px;
    }
}

@media (max-width: 1430px){
    .homepage-hero{
        background-position-y: center;
        background-position-x: center;
    }
}
/* Hero Section End */
