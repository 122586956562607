footer{
    background-color: #02010D;
}

footer .main-footer{
    padding-top: 60px;
    padding-bottom: 40px;
}

footer .main-footer .container{
    display: flex;
    justify-content: space-around;
    gap: 70px;
}


@media (max-width: 992px) {
    footer .main-footer .container {
        flex-wrap: wrap;
        gap: 50px;
    }
}

footer .main-footer .container .block{
    width: 270px;
}

@media (max-width: 668px) {
    footer .main-footer .container .block{
        width: 100%;
    }
}

footer .main-footer .container .block .text-logo{
    text-transform: capitalize;
    font-size: 34px;
    font-family: 'Libre Bodoni', serif;
    font-weight: 700;
    font-style: italic;
    margin-bottom: 18px;
}

footer .main-footer .container .block p{
    font-size: 17px;
    line-height: 21px;
    color: #C9D2D8;
}




footer .main-footer .container .block h3{
    font-size: 22px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 4300;
    text-transform: uppercase;
}

footer .main-footer .container .block ul{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

footer .main-footer .container .block ul li{
    color: #C9D2D8;
    font-size: 20px;
    text-transform: capitalize;
}

footer .main-footer .container .block ul li a{
    color: #C9D2D8;
    transition: .3s;
    text-transform:none;
}

footer .main-footer .container .block ul li a:hover{
    color: #f1f6f8;
}

footer .footer-bottom{
    /* height: 70px; */
    background-color: #03030A;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 25px 55px;
}
footer .footer-bottom p{
    font-size: 18px;
    font-family: 'Poppins';
    text-align: center;
}

@media (max-width: 800px){
    footer .footer-bottom p{
        font-size: 16px;
    }
}

footer .main-footer .container .block .social-links{
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

footer .main-footer .container .social-links .i{
    font-size: 28px;
    color: var(--bluish-white);
    transition: .3s;
    opacity: .8;
}

footer .main-footer .container .social-links .i:hover{
    opacity: 1;
    color: #f1f6f8;
}

footer .main-footer .container.sml .left.play{
    display: flex;
    justify-content: center;
}

@media (max-width: 1430px){
    footer .main-footer .container .block h3{
        font-size: 20px;
    }
    footer .main-footer .container .block  ul li{
        font-size: 18px;
    }
    footer .main-footer .container .social-links .i{
        font-size: 30px;
    }
}