
/* Continuous Delivery */

.continuous-delivery .container{
    padding-left: 100px;
}

@media (max-width: 1200px) {
    .continuous-delivery .container{
        padding-left: unset;
    }
}

.continuous-delivery .inner-container .left.icon .i{
    color: #F35D9E;
}

.continuous-delivery .inner-container .left.icon::before{
    background-image: radial-gradient( #F35D9E, #412f2000 70%);
}


.continuous-delivery .inner-container .left .bar {
    height: 100%;
    width: 3px;
    background-image: linear-gradient( #F35D9E 60%, #F32424);
}

.continuous-delivery .inner-container .content{
    height: 100%;
}

.continuous-delivery .inner-container .content p span{
    color: #F35D9E;
}

.continuous-delivery .link{
    margin-bottom: 50px;
}

@media (max-width: 780px){
    .continuous-delivery .container{
        padding-left: 0;
    }  
}

.continuous-delivery ul {
    display: flex;
    gap: 10px;
    margin-left: 30px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.continuous-delivery ul li {
    font-size: 25px;
    text-transform: capitalize;
    color: white;
}

.continuous-delivery ul li a {
    color: white;
    width: 120px;
    display: block;
    position: relative;
    z-index: 1;
    text-align: center;
}

.continuous-delivery ul li a::after {
    content: '';
    position: absolute;
    height: 25px;
    width: 100%;
    background-color: #2F2F36;
    /* opacity: .15; */
    transform: translateX(-50%);
    left: 50%;
    left: 0;
    top: 50%;
    z-index: -1;
    transform: skew(-30deg);
    transition: .3s;
}
.continuous-delivery ul li a::before {
    transition: .3s;
    content: '';
    position: absolute;
    height: 25px;
    width: 100%;
    background-color: #2F2F36;
    /* opacity: .15; */
    transform: translateX(-50%);
    left: 50%;
    left: 0;
    bottom: calc(50%+1px);
    bottom: 50%;
    z-index: -1;
    transform: skew(30deg);
}

.continuous-delivery ul li a:hover::before,
.continuous-delivery ul li a:hover::after{
    background-color: #5B3C4D;
}

@media (max-width: 780px){
    .continuous-delivery ul li{
        font-size: 22px;
        font-weight: 400;
    }
}

@media (max-width:800px){
    .continuous-delivery .inner-container .content div{
        display: flex;
    }
    .continuous-delivery ul {
        flex-direction: column;
        gap: 32px;
    }
    .continuous-delivery ul li a{
        width: 130px;
    }
    .continuous-delivery ul li a::before{
        height: 50px;
        width: 50%;
        background-color: #2F2F36;
        /* opacity: .15; */
        transform: translateX(-50%);
        left: 50%;
        left: 0;
        /* bottom: calc(50%+1px); */
        bottom: -8px;
        z-index: -1;
        transform: skewy(10deg);
    }
    .continuous-delivery ul li a::after{
        height: 50px;
        width: 50%;
        background-color: #2F2F36;
        /* opacity: .15; */
        transform: translateX(-50%);
        /* left: 50%; */
        /* right: 0; */
        left: calc(50% - 1px);
        top: unset;
        bottom: -8px;
        z-index: -1;
        transform: skewy(-10deg);
    }
}

/* Continuous Delivery End */
