
/* poftolio */

.portfolio .container{
    padding-left: 100px;
    z-index: 2;
    position: relative;
}


/* .portfolio .container:nth-child(3){
    padding-left: unset;
} */

@media (max-width: 1200px) {
    .portfolio .container{
        padding-left: unset;
    }
}

@media (max-width: 1400px) {
    .portfolio .container:nth-child(3){
        padding-left: 10px;
    }
}

.portfolio{
    position: relative
}

.portfolio-background {
    position: absolute;
    height: 110%;
    width: 100%;
    transform: translateY(-50%);
    top: 50%;
    background: url('../../assets/jeremy-thomas-4dpAqfTbvKA-unsplash.jpg');
    opacity: .12;
    background-size: cover;
}

.portfolio-background::before,
.portfolio-background::after{
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 30%;
    box-sizing: border-box;
    z-index: 0;
}
.portfolio-background::after{
    background: linear-gradient(0deg, #03030A ,#03030a00 60%);
    bottom: 0;
    
}
.portfolio-background::before{
    background: linear-gradient(0deg, #03030a00 60%,#03030A);
    top: 0;

}


.portfolio .inner-container .left.icon .i{
    color: #F32424;
}

.portfolio .inner-container .left.icon::before{
    background-image: radial-gradient( #F32424, #412f2000 70%);
}


.portfolio .inner-container .left .bar {
    height: 100%;
    width: 3px;
    background-image: linear-gradient( #F32424 60%, #412f2000);
}

.portfolio .inner-container .content{
    height: 100%;
}

.portfolio .inner-container .content p span{
    color: #F35D9E;
}

.portfolio .link{
    margin-bottom: 50px;
}

.card{
    background-color: rgba(255, 255, 255, 0.1);
    height: 490px;
    border-radius: 20px;
    backdrop-filter: blur(3px);
    padding: 60px 100px 80px;
    position: relative;
}

.small-cards-container{
    display: flex;
    gap: 30px;
    margin-top: 30px;
}

@media (max-width: 992px){
    .card{
        height: 550px;
        width: 100%;
        max-width: 390px;
        min-width: 320px;
        padding: 20px;
    }

    .small-cards-container{
        flex-direction: column;
    }
}
@media (max-width: 992px){
    .portfolio-cards>.card .card-content{
        flex-direction: column-reverse;
        justify-content: start;
        gap: 15px;
    }
}

.small-cards-container .card{
    height: 790px;
}

.card .card-content{
    display: flex;
    justify-content: space-between;
}

.small-cards-container .card .card-content{
    flex-direction: column-reverse;
}

.card .card-content{
    height: 100%;
    width: 100%;
}

.card .card-content .card-disc{
    margin-right: 80px;
}

.card .card-content .card-disc h3{
    font-size: 35px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    text-transform: capitalize;
    padding-right: 80px;
}

.card .card-content .card-disc p{
    font-size: 25px;
    margin-bottom: 25px;
    color: rgba(240, 248, 255, 0.918);
}

.card .card-content .card-disc-container{
    display: flex;
    align-items: center;
    height: 100%;
    width: 50%;
}

.small-cards-container .card .card-content .card-disc-container{
    width: 100%;
}

.card .link{
    margin: 0;
}

.card .card-presentation{
    width: 50%;
    display: flex;
    align-items: center;

}
@media (max-width: 1200px){
    .card{
        height: 400px;
    }
    .card .card-content .card-disc p{
        font-size: 20px;
        /* line-height: 22px; */
        margin-bottom: 15px;
    }
}

@media (max-width: 1200px){
    .small-cards-container .card{
        padding: 25px;
        height: 600px;
    }
    .small-cards-container .card .card-content .card-disc-container{
        height: unset;
        /* margin-bottom: 40px; */
    }
    .small-cards-container .card .card-content .card-disc{
        margin: unset;
        padding: 20px;

    }
    .small-cards-container .card .card-content .card-disc h3{
        font-size: 28px;
        text-align: center;
        padding: unset;
    }
    .small-cards-container .card .card-content .card-disc p{
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 15px;
    }
    .small-cards-container .card .card-presentation{
        width: unset;
    }
    .card .link{
        font-size: 25px;
    }
    .small-cards-container .card .card-presentation img{
        border-radius: 1.2em;
    }
}


@media (max-width: 992px){
    .card,
    .small-cards-container .card{
        height: 550px;
    }
    .card .card-content .card-disc-container{
        height: unset;
        /* margin-bottom: 40px; */
    }
    .card .card-content .card-disc{
        margin: unset;
        padding: 20px;

    }
    .card .card-content .card-disc h3{
        font-size: 28px;
        text-align: center;
        padding: unset;
    }
    .card .card-content .card-disc p{
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 15px;
    }
    .card .card-presentation{
        width: unset;
    }
    .card .card-presentation img{
        border-radius: 1.2em;
    }
}

.small-cards-container .card .card-content .card-presentation{
    width: 100%;
}

.card .card-presentation img{
    width: 100%;
}

.card .arrow{
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    padding: 15px;
}

.card .arrow.left{
    left: 50px;
}

.card .arrow.right{
    right: -25px;
}

.card .arrow .i{
    font-size: 50px;
    font-weight: bold;
    transition: .3s;
    color: #856161;
}

.card .arrow:hover{
    cursor: pointer;
}

.card .arrow:hover .i{
    color: #F32424;
}

.card .slides{
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    bottom: 30px;
    /* width: 130px; */
    height: 10px;
    /* background-color: red; */
    display: flex;
    gap: 8px;
}

.card .slides .slide{
    width: 28px;
    height: 9px;
    background-color: #856161;
    transition: .3s;
    border-radius: 1em;

}

.card .slides .slide:hover{
    background-color: #F32424;
    cursor: pointer;
}

.card .slides .slide.active{
    width: 50px;
    height: 9px;
    background-color: #F32424;
}

@media (max-width: 1200px){
    .small-cards-container .card .card-content .card-disc-container{
        width: 100%;
    }
    .small-cards-container .card .card-content{
        justify-content: start;
        gap: 15px;
    }
    .small-cards-container .card .slides{
        gap: 6px;
    }
    .small-cards-container .card .slides .slide.active{
        height: 6px;
        width: 40px;
    }
   .small-cards-container  .card .slides .slide{
        height: 7px;
        width: 25px;
    }
    .small-cards-container .card .arrow{
        top: calc(50% );
    }
    .small-cards-container .card .arrow .i{
        font-size: 40px;
    }
    .small-cards-container .card .arrow.right{
        /* transform: translatey(100%); */
        right: -21px;
    }
}

@media (max-width: 992px){
    .card .card-content .card-disc-container{
        width: 100%;
    }
    .small-cards-container .card .card-content{
        justify-content: start;
        gap: 15px;
    }
    .card .slides{
        gap: 6px;
    }
    .card .slides .slide.active{
        height: 6px;
        width: 40px;
    }
    .card .slides .slide{
        height: 7px;
        width: 25px;
    }
    .small-cards-container .card .arrow,
    .card .arrow{
        top: calc(50% - 10px);
    }
    .card .arrow .i{
        font-size: 40px;
    }
    .card .arrow.right{
        /* transform: translatey(100%); */
        right: -21px;
    }
}

@media (max-width: 380px){
    .card .arrow{
        top: calc(50% - 30px);
    }
}

/* Poftolio End */
