.homepage{
    background-color: #03030A;
    position: relative;
}

.homepage.popup-is-active{
    height: 100vh;
    overflow: hidden;
}

.popup{
    position: absolute;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.72);
    top: 0;
    z-index: 99999;
    backdrop-filter: blur(5px);
    /* display: flex; */
    justify-content: center;
    align-items: center;
    display: none;
}

.popup.active{
    display: flex;
}

.popup h1{
    font-size: 30px;
    text-transform: capitalize;
    text-align: center;
    letter-spacing: 4px;
}
.popup h2{
    font-size: 25px;
    text-align: center;
    text-transform: capitalize;
}

.popup .popup-box{
    position: relative;
    background-color: rgba(255, 255, 255, 0.249);
    padding: 20px 50px;
    border-radius: 10px;
}

.popup .close-popup{
    position: absolute;
    top: -50px;
    right: -50px;
    background-color: white;
    color: black;
    padding: 5px;
    font-size: 35px;
    cursor: pointer;
}

@media (max-width: 500px){
    .popup h1{
        font-size: 22px;
    }
    .popup h2{
        font-size: 18px;
    }
    .popup .close-popup{
        right: 0;
    }
}