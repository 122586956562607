
.call-to-action-section{
    /* height: 1200px; */
    padding-bottom: 100px;
    height: fit-content;
    position: relative;
}

.call-to-action-section .cta-background{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('../../assets/jake-weirick-Q_RBVFFXR_g-unsplash.jpg');
    z-index: 1;
    background-size: cover;
    opacity: .2;
}

.call-to-action-section .cta-background::after{
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 30%;
    box-sizing: border-box;
    z-index: 0;
    background: linear-gradient(0deg, #03030a00 60%,#03030A);
    top: 0;

}

.call-to-action-section .container{
    z-index: 2;
    position: relative;
    padding-top: 100px;
}

/* text reveal */
.call-to-action-section .container h1 span,
.call-to-action-section .container p span{
    color: hsl(0 0% 100% / 0.2);
    background-clip: text;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    background-image: linear-gradient(90deg, white, white);
    animation: scroll-reveal linear forwards;
    animation-timeline: view(y);
}
.call-to-action-section .container h1 span{
    animation-range-start: cover 20vh;
    animation-range-end: cover 30vh;
}
.call-to-action-section .container p span{
    animation-range-start: cover 22vh;
    animation-range-end: cover 40vh;
}

@keyframes scroll-reveal{
    to{
        background-size: 100% 100%;
    }
}

/* text reveal -- end -- */

.call-to-action-section .container h1{
    font-size: 45px;
    font-family: arial;
    font-weight: 900;
    width: 700px;
    text-align: center;
    margin: auto;
}

.call-to-action-section .container p{
    font-size: 25px;
    font-family: helvetica;
    /* font-weight: 900; */
    width: 800px;
    text-align: center;
    margin: auto;
    opacity: .75;
    margin-top: 20px;
    margin-bottom: 30px;
}

.call-to-action-section .call-to-action{
    display: flex;
    gap: 15px;
    width: fit-content;
    margin: auto;
}

.call-to-action-section .content.call-to-action button{
    background-color: white;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 23px;
    text-transform: capitalize;
    width: 260px;
    height: 40px;
    color: #02010D;
}



.call-to-action-section .content.call-to-action button:last-child{
    background-color: transparent;
    color: white;
    border: 2px solid white;
}

@media (max-width: 1430px){
    .call-to-action-section .content.call-to-action button{
        font-size: 16px;
        height: 30px;
        width: 180px;
    }
}

@media (max-width: 900px){
    .call-to-action-section .content.call-to-action button{
        width: 150px;
    }
}

@media (max-width: 450px){
    .call-to-action-section .content.call-to-action button{
        width: unset;
        padding: 5px 15px;
    }
}

@media (max-width: 1000px){
    .call-to-action-section .container h1{
        font-size: 25px;
        width: unset;
        line-height: 35px;
    }
    .call-to-action-section .container p{
        font-size: 20px;
        width: unset;
        line-height: 22px;
        margin-top: 15px;
    }
}