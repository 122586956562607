
/* Technologies I Use Section */

.tech-section .container{
    padding-left: 100px;
}
@media (max-width: 1200px) {
    .tech-section .container{
        padding-left: 0;
    }
}

.tech-section .inner-container .left.icon .i{
    color: #8CEFD4;
}

.tech-section .inner-container .left.icon::before{
    background-image: radial-gradient( #8CEFD4, #412f2000 70%);
}

.tech-section .techs{
    height: 200px;
}

/* Technologies I Use Section End */

