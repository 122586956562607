
.container {
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
}

/* Small */

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}

@media (max-width: 768px) {
    .container {
        padding-left: 10px;
        padding-right: 10px;
    }
}


/* Medium */

@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}


/* Large */

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

/* Extra Large */

@media (min-width: 1400px) {
    .container {
        width: 1370px;
    }
}


/* main title */