:root{
    --bluish-white: #EBECFF;
}

body{
    color: white;
    /* height: 2000px; */
}
pre{
    background: rgb(21, 14, 28) !important;
}
code .keyword{
    color: rgb(116, 87, 173);
}
code .punctuation{
    color: rgb(86, 139, 180);
}
code .function{
    color: rgb(187, 17, 102);
}
code .method{
    color: rgb(73, 181, 195);
}
code .imports{
    color: rgb(73, 181, 195);
}
code .string{
    color: rgb(80, 165, 46);
}
code .operator{
    color: rgb(216, 222, 165);
}
code .arrow.operator{
    color: rgb(165, 173, 222);
}
code .comment{
    color: rgb(96, 132, 104);
}
code .linenumber{
    color: rgb(152, 152, 152);
}
code .tag{
    color: rgb(66, 114, 151);
}
code .tag.punctuation{
    color: rgb(136, 162, 182);
}
code .tag.attr-name{
    color: rgb(136, 162, 182);
}
code .tag.attr-value{
    color: rgb(80, 165, 46);
}
code .tag.attr-equals{
    color: rgb(216, 222, 165);
}


.link{
    color: white;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 27px;
    display: flex;
    align-items: center;
    gap: 5px;
    transition: .3s;
    width: fit-content;
    position: relative;
    text-transform: capitalize;
}

.link::before{
    content: '';
    height: 3px;
    width: 0%;
    background-color: white;
    position: absolute;
    bottom: -5px;
    left: 0;
    transition: .3s;
}

.link:hover{
    gap: 15px;
}

.link:hover::before{
    width: 97%;
}

.inner-container{
    display: flex;
    align-items: center;
}
.inner-container.no-align-center{
    align-items: unset;
}

.inner-container .left{
    width: 80px;
    display: flex;
    justify-content: center;
}

.inner-container .left .top-bar{
    height: 120px;
    width: 3px;
    background-color: red;
}

.inner-container .left.icon{
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

 .inner-container .left.icon .i{
    font-size: 35px;
    margin: 5px;
    position: relative;
}

 .inner-container .left.icon::before{
    content: '';
    position: absolute;
    transform: translate(-50%, -50%);
    opacity: .35;
    left: 50%;
    top: 50%;
    height: 60px;
    width: 60px;
    border-radius: 50%;
}

@media (max-width: 780px){
     .left .top-bar{
        height: 80px;
    }
    .link{
        font-size: 18px;
    }
    .inner-container .left{
        width: 50px;
    }
    .inner-container .left.icon .i{
        font-size: 25px;
    }
    .inner-container .left.icon::before{
        height: 50px;
        width: 50px;
    }
}

.inner-container .content{
    width: calc(100% - 80px);
}

.inner-container .content p{
    margin-top: 25px;
    margin-bottom: 50px;
    font-size: 22px;
    max-width: 900px;
    margin-top: 10px;
    opacity: .95;
    margin-bottom: 30px;
        /* line-height: 20px; */
    
}

.inner-container .content.title h2{
    font-size: 40px;
    margin: 0;
    text-transform: capitalize;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
}

@media (max-width: 780px){
    .inner-container .content{
        width: calc(100% - 50px);
    }
    .inner-container .content.title h2{
        font-size: 25px;
    }
    .inner-container .content p{
        font-size: 18px;
        width: calc(100%);
        line-height: 22px;
    }
}

/* Testimonial section */

.testimonial-section{
    /* height: 150px; */
    margin-top: -50px;
    padding-bottom: 0;
}
.testimonial-section.red{
    background-color: red;
}

.testimonial-section .container>div{
    /* display: grid;
    gap: 10px;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    overflow-x: auto;
    overscroll-behavior-inline: contain;
    scrollbar-width: thin;
    scroll-padding-inline: -100px; */
    /* width: 70%; */
    display: grid;
    gap: 15px;
    grid-auto-flow: column;
    overflow-x: hidden;
    position: relative;
    z-index: 2;
    /* border: 1px red solid; */
}

/* Testimonial Section End */
