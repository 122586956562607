
/* Aim for a great software */

.great-software .container{
    padding-left: 100px;
}

.great-software .inner-container .left.icon .i{
    color: #F35D9E;
}

.great-software .inner-container .left.icon::before{
    background-image: radial-gradient( #F35D9E, #412f2000 70%);
}


.great-software .inner-container .left .bar {
    height: 100%;
    width: 3px;
    background-image: linear-gradient( #F35D9E 60%, #412f2000);
}

.great-software .inner-container .content{
    /* height: 100%; */
}


.great-software .inner-container .content p span{
    color: #F35D9E;
}

@media (max-width: 1200px){
    .great-software .container{
        padding-left: 0;
    }
}
@media (max-width: 780px){
    .great-software .container{
        padding-left: 0;
    }
    .great-software .inner-container .content p{
        font-size: 18px;
        width: calc(100%);
        line-height: 22px;
    }
    .great-software .container:nth-child(2){
        padding-left: 10px;
    }
}

.great-software .container:nth-child(2){
    padding-left: unset;
}

.great-software .code-snippet{
    display: flex;
    border-radius: 16px;
    background-color: #0D1117;
    border: 2px solid #4e4e4e;
    position: relative;
    height: 730px;
    box-shadow: rgba(50, 50, 93, 0.15) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

@media (max-width: 1400px) {
    .great-software .container:nth-child(2){
        padding-left: 10px;
    }
}

.great-software .container:last-child .inner-container .content{
    padding: 40px 0 50px;
}

.great-software .container:last-child .inner-container .content p{
    max-width: 690px;
}

.great-software .container:last-child .inner-container .left .bar{
    background-image: linear-gradient(#412f2000, #F35D9E 60%);
}

/* Aim for a great software End */
