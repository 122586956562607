
/* uiux-portfolio-section */


.uiux-portfolio-section .container:first-child{
    padding-left: 100px;
}
@media (max-width: 1200px) {
    .uiux-portfolio-section .container:first-child{
        padding-left: unset;
    }
}

@media (max-width: 1400px) {
    .uiux-portfolio-section .container:nth-child(2){
        padding-left: 10px;
    }
}

.uiux-portfolio-section .inner-container .left.icon .i{
    color: #5DF35D;
}

.uiux-portfolio-section .inner-container .left.content-bar{
    height: 270px;
}

.uiux-portfolio-section .inner-container .left.icon::before{
    background-image: radial-gradient( #5DF35D, #412f2000 70%);
}

.uiux-portfolio-section .inner-container .left .bar {
    height: 100%;
    width: 3px;
    background-color: #5DF35D;
}

.uiux-portfolio-section .inner-container .left .bar.end {
    height: 100%;
    width: 3px;
    height: 120px;
    background: linear-gradient(#5DF35D, #412f2000);
}

.uiux-portfolio-section .inner-container .left .top-bar{
    background: linear-gradient( #412f2000, #5DF35D);
}

.uiux-portfolio-section .inner-container .content{
    height: 100%;
    display: flex;
    justify-content: space-between;
    
}
.uiux-portfolio-section .inner-container .content img{
    width: 550px;
    opacity: .9;
    margin-top: -125px;
}

.uiux-portfolio-section .inner-container .content p {
    max-width: 550px;
}

.uiux-portfolio-section .inner-container .content p span{
    color: #5DF35D;
}

@media (max-width: 780px){
    .uiux-portfolio-section .container{
        padding-left: 0;
    }
}

.uiux-portfolio-section .inner-container .content.extention{
    position: relative;
    margin-top: -23px;
}

.uiux-portfolio-section .inner-container .content.extention::before{
    border-bottom: 3px solid #5DF35D;
    border-left: 3px solid #5DF35D;
    position: absolute;
    content: '';
    border-bottom-left-radius: 20px;
    height: 100px;
    width: 30px;
    left: -30px;
    top: -150px;
}

.uiux-portfolio-section .inner-container .content.extention::after{
    border-top: 3px solid #5DF35D;
    border-right: 3px solid #5DF35D;
    position: absolute;
    content: '';
    border-top-right-radius: 20px;
    height: 70px;
    width: 41px;
    left: 0;
    top: -52.5px;
}

.uiux-portfolio-section .inner-container .content.extention .inner-inner-container{
    display: flex;
    align-items: center;
}

.uiux-portfolio-section .inner-container .content.extention .inner-inner-container .left{
    width: 80px;
    display: flex;
    justify-content: center;
}

.uiux-portfolio-section .inner-container .content.extention .inner-inner-container .left .i.small{
    font-size: 14px;
}

.uiux-portfolio-section .inner-container .content.extention .inner-inner-container .left.icon::before{
    height: 50px;
    width: 50px;
    background-image: radial-gradient( #5DF35D , #03030a00 55%);
}

.uiux-portfolio-section .inner-container .content.extention .inner-inner-container .left.icon::after{
    content: '';
    position: absolute;
    width: 3px;
    height: 120px;
    background: linear-gradient(#5DF35D,#03030a00);
    transform: translateX(-50%);
    left: 50%;
    top: 14px;
}


.uiux-portfolio-section .inner-container .content.extention .inner-inner-container .left .top-bar{
    height: 120px;
    width: 3px;
    background-color: red;
}

@media (max-width: 1200px) {
    
    .uiux-portfolio-section .inner-container .content img{
        width: 400px;

        opacity: .8;
    }
    
    .uiux-portfolio-section .inner-container .content p {
        max-width: 550px;
        margin-top: 10px;
        margin-bottom: 60px;
    }
    .uiux-portfolio-section .inner-container .content.extention::before{
        height: 100px;
        width: 43px;
        left: -32px;
        top: -118px;
    }
    .uiux-portfolio-section .inner-container .content.extention::after{
        top: -21px;
        height: 34px;
    }
}

@media (max-width: 992px){
    .uiux-portfolio-section .inner-container .content{
        display: block;
        position: relative;
    }
    .uiux-portfolio-section .inner-container .content img{
        width: 220px;
        opacity: .4;
        position: absolute;
        right: 0;
        top: -40px;
    }
    .uiux-portfolio-section .inner-container .left .bar.end{
        height: 80px;
    }
    .uiux-portfolio-section .inner-container .content.extention .inner-inner-container .left.icon::after{
        height:86px;
    }
}
@media (max-width: 780px){
    .uiux-portfolio-section .inner-container .content.extention::after{
        width: 22px;
        left: 16px;
    }
    .uiux-portfolio-section .inner-container .content.extention::before{
        width: 37px;
        left: -18px;
    }
}
.uiux-portfolio-section .card .arrow .i{
    /* font-size: 50px; */
    font-weight: bold;
    transition: .3s;
    color: #5A7E5A;
}

.uiux-portfolio-section .card .arrow:hover{
    cursor: pointer;
}

.uiux-portfolio-section .card .arrow:hover .i{
    color: #5DF35D;
}

.uiux-portfolio-section .card .slides{
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    bottom: 30px;
    /* width: 130px; */
    height: 10px;
    /* background-color: red; */
    display: flex;
    gap: 8px;
}

.uiux-portfolio-section .card .slides .slide{
    width: 28px;
    height: 9px;
    background-color: #5A7E5A;
    transition: .3s;
    border-radius: 1em;

}

.uiux-portfolio-section .card .slides .slide:hover{
    background-color: #5DF35D;
    cursor: pointer;
}

.uiux-portfolio-section .card .slides .slide.active{
    width: 50px;
    height: 9px;
    background-color: #5DF35D;
}

/* ui ux portfolio section end */
