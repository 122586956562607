
.testimonial-card{
    width: 398px;
    height: 587px;
    background-color: #80a7b834;
    border-radius: 15px;
    padding: 50px;
    display: flex;
    align-items: center;
}

/* .testimonial-card>div{
    border: 1px red solid;
} */

.testimonial-card h3{
    font-size: 20px;
    text-transform: capitalize;
    margin: 0;
}

.testimonial-card .i{
    font-size: 80px;
    color: #F4F4F6;
}

.testimonial-card p{
    font-size: 20px;
    font-family: helvetica;
    margin-top: 30px;
    padding-bottom: 35px;
    color: rgba(255, 255, 255, .9);
}