header {
    font-family: "Poppins", sans-serif;
    position: fixed;
    width: 100%;
    transition: .25s;
    z-index: 9999;
    top: 25px;
    top: 0;
    height: 80px;
    backdrop-filter: blur(5px);
}

.not-working{
    color: #aaa !important;
}

/* header::before{
    height: 100%;
    position: absolute;
    content: '';
    width: 100%;
    background: linear-gradient(0deg, rgba(0,0,0,0.5) 25%, rgba(0,0,0,0) 100%);
    opacity: .8;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 0%;
    z-index: -1;
} */

/* header::after{
    height: 100%;
    position: absolute;
    content: '';
    width: 100%;
    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 75%);
    opacity: .8;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 100%;
    z-index: -1;
} */
header::after{
    height: 100%;
    position: absolute;
    content: '';
    width: 100%;
    /* background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 75%); */
    background: rgba(0,0,0,0.75);
    opacity: .8;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    z-index: -1;
}

header .container{
    display: flex;
    gap: 40px;
    height: inherit;
    align-items: center;

}

header nav{
    display: flex;
    justify-content: space-between;
    flex: 1;

}

header nav.active{
    flex-direction: column;
    flex: unset;
}

.burger {
    height: fit-content;
    transition: 0.25s;
    display: none;
}

.burger span {
    height: 3px;
    width: 25px;
    background-color: white;
    display: block;
    transition: inherit;
}

.burger span:not(:last-child) {
    margin-bottom: 4px;
}

.burger.active span:nth-child(2) {
    opacity: 0;
}

.burger.active span:nth-child(1) {
    transform: rotate(-45deg) translate(-3px, 7px);
}

.burger.active span:nth-child(3) {
    transform: rotate(45deg) translate(-3px, -7px);
}

@media (max-width: 1200px) {
    .burger {
        display: block;
        padding-left: 15px;
        padding-right: 15px;
        cursor: pointer;
    }
    header nav {
        display: none;
    }

    header .container{
        justify-content: space-between;
        align-items: center;
    }
}


header .container .left-part{
    display: flex;
    align-items: center;
    gap: 40px;
    text-transform: capitalize;
}

header .container .text-logo{
    font-size: 23px;
    font-family: 'Libre Bodoni', serif;
    font-weight: 700;
    font-style: italic;
    text-transform: capitalize;
}

header .container .text-logo a{
    color: white;
}

header .left-part ul{
    display: flex;
    gap: 10px;
    font-size: 18px;
    font-family: "Poppins";
}

header .left-part ul li{
    position: relative;
    padding: 10px 15px;
    cursor: pointer;
}

header .left-part ul li a{
    color: white;
}
header .left-part ul li.not-working a{
    color: #aaa;
}

header .left-part ul li:before{
    width: 0%;
    height: 2px;
    background-color: white;
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: .3s;
}

header .left-part ul li:hover:before{
    width: 100%;
}
header .left-part ul li.not-working:hover:before{
    width: 0%;
}

header .right-part .contact-btn {
    color: black;
    background-color: var(--bluish-white);
    font-size: 18px;
    padding: 3px 22px;
    border-radius: 5px;
    font-weight: 500;
    text-transform: capitalize;
    cursor: pointer;
}

header .right-part{
    display: flex;
    align-items: center;
}

header nav.active .left-part{
    justify-content: center;
}

header nav.active .right-part{
    justify-content: center;
    flex-direction: column-reverse;
    gap: 15px;
    margin-top: 15px;
    margin-bottom: 20px;
}

header .right-part .resume-link{
    font-size: 18px;
    color: white;
    padding: 3px 22px;
}

header .right-part form input[type="text"]{
    background-color: rgba(255,255,255, .25);
    font-size: 16px;
    width: 170px;
    color: white;
    padding: 5px 10px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

header .right-part form{
    display: flex;
}

header .right-part form button{
    background-color: rgba(255,255,255, .25);
    font-size: 16px;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;
    transition: .3s;
    color: white;
}

header .right-part form button:hover{
    background-color: rgba(255,255,255, .35);
}


header nav.active {
    display: block;
    position: absolute;
    z-index: 9999;
    top: 90px;
    width: 75vw;
    right: 25px;
    transition: 0.3s;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(7px);
}

header nav.active a {
    color: white;
}

header nav.active .links a {
    color: white;
}

header nav.active::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: -30px;
    right: 6px;
    border: 15px solid;
    border-color: transparent transparent rgba(0, 0, 0, 0.75) transparent;
}

header nav.active ul {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-top: 20px;
}

header nav.active ul li::before {
    background-color: white;
    height: 2px;
}

header nav.active .links {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}


@media (max-width: 1370px) {
    header .right-part .contact-btn,
    header .right-part .resume-link,
    header .left-part ul {
        font-size: 16px;
    }

    header  .left-part ul{
        gap: 0px;
    }

    header .container .text-logo{
        font-size: 21px;
    }

    header .container{
        gap: 30px;
    }

    header .right-part form input[type="text"]{
        font-size: 14px;
    }

}