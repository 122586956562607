
/* About Section */


.about-section .container{
    padding-left: 100px;
}

.about-section .inner-container .left.icon .i{
    color: #8CEFD4;
}

.about-section .inner-container .left.content-bar{
    height: 270px;
}

.about-section .inner-container .left.icon::before{
    background-image: radial-gradient( #8CEFD4, #412f2000 70%);
}

.about-section .inner-container .left .bar {
    height: 100%;
    width: 3px;
    background-image: linear-gradient( #8CEFD4 60%, #412f2000);

}

.about-section .inner-container .left .top-bar{
    background: linear-gradient( #412f2000, #8CEFD4);
}

.about-section .inner-container .content{
    height: 100%;
}

.about-section .inner-container .content p span{
    color: #8CEFD4;
}

@media (max-width: 780px){
    .about-section .container{
        padding-left: 0;
    } 
}

.about-section .container:nth-child(2){
    padding-left: 0;
}

@media (max-width: 1400px) {
    .about-section .container:nth-child(2){
        padding-left: 10px;
    }
}

.about-section .about-card{
    display: flex;
    border-radius: 16px;
    background-color: #0D1117;
    border: 2px solid #4e4e4e;
    position: relative;
    height: 500px;
    box-shadow: rgba(50, 50, 93, 0.15) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.about-section .about-card .floating-pic{
    position: absolute;
    bottom: -150px;
    right: -100px;
    background-color: #02010D;
    /* box-shadow: rgba(0, 0, 255, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
    box-shadow: rgba(0, 0, 0, 0.9) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    /* border: 2px solid #707070;
    border-radius: 20px; */
}

.about-section .about-card .floating-pic>img{
    /* border-radius: 20px; */
    width: 250px;
    opacity: .5;
}

.about-section .about-card div:nth-child(2){
    padding: 60px 50px;
}

.about-section .about-card p{
    font-size: 25px;
    max-width: 720px;
}

.about-section .about-card p span{
    color: #8CEFD4;
}

.about-section .about-card p:first-child{
    margin-bottom: 40px;
}

.about-section .about-card .seif-pic{
    height: 500px;
    border-radius: 16px 0 0 16px;
    margin: 0;
}

@media (max-width: 992px) {
    .about-section .about-card {
        max-width: 100%;
        flex-direction: column;
        height: fit-content;
    }
    
    .about-section .about-card div:nth-child(2){
        padding: 40px 20px;
    }
    .about-section .about-card div:nth-child(2) p{
        margin: 0;
    }
    .about-section .about-card div:nth-child(2) p:first-child{
        margin-bottom: 20px;
    }
    .about-section .about-card div:nth-child(1){
        margin: auto;
    }
    .about-section .about-card .seif-pic{
        max-width: 400px;
        /* height: 500px; */
        width: 100%;
        height: auto;
        border-radius: 16px 16px 0 0;
        margin: 0;
    }
}


 
@media (max-width: 1590px){
    .about-section .about-card p{
        max-width: 600px;
    }
    .about-section .about-card .floating-pic{
        right: -2%;
    }
}


@media (max-width: 1400px) {
    .about-section .about-card .floating-pic{
        display: none;
    }
}

@media (max-width: 1200px) {
    .about-section .container{
        padding-left: 0;
    }
    .about-section .about-card p{
        font-size: 22px;
        max-width: 720px;
    }
}

.about-section .container:last-child .inner-container .content{
    padding: 40px 0 50px;
}

.about-section .container:last-child .inner-container .content p{
    max-width: 690px;
}

.about-section .container:last-child .inner-container .left .bar{
    background-image: linear-gradient(#412f2000, #8CEFD4 60%);
}

/* About Secion End */
